<template>
  <!-- single page -->
  <div class="page-main-area-center">
    <div class="content content-block content-bg corner-radius-all article-block">
      <div class="label-title-content">
        About <strong>Me</strong>
      </div>
      <hr/>
      <div v-html="content" class="label-normal"></div>
    </div>
    <router-view/>
    <!-- <Spin size="large" fix v-if="isLoading"></Spin> -->
  </div>
</template>

<script>
import {ref, onMounted} from 'vue'
import Axios from 'axios'
import homeManager from './manager/HomeManager.js'
export default {
  name: 'About',
  setup() {
    const content = ref('')
    const isLoaded = ref(false)

    onMounted(() => {
      homeManager.getAboutMe((success, obj) => {
        if (success) {
          content.value = obj.content.rendered
        }
        else {
          //
        }
        isLoaded.value = true
      })
    })

    return {
      content,
      isLoaded
    }
  }
}
</script>
